.invoice-container {
  padding: 20px;
  margin: 20px;
  border: 1px solid #ddd;
  width: 100%;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.company-details {
  width: 60%;
}

.invoice-details {
  width: 40%;
  text-align: right;
}

.company-details p, .invoice-details p, .bill-to p {
  margin: 2px 0;
  padding: 0;
}

.bill-to {
  margin-bottom: 20px;
}

.invoice-table {
  width: 100%;
  margin-bottom: 20px;
}

.invoice-table th, .invoice-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.invoice-table th {
  background-color: #4a89e5;
  color:white;
}

.invoice-table tfoot tr td {
  font-weight: bold;
  text-align: right;
}
tfoot tr td:nth-child(2), /* Adjust the child number to match the column index */
tfoot tr td:nth-child(3),
tfoot tr td:nth-child(4),
tfoot tr td:nth-child(5),
tfoot tr td:nth-child(6) {
    text-align: left;
}

.print-preview-button {
  margin-bottom: 20px;
}
.css-wqx6av-MuiButtonBase-root-MuiButton-root {
  background-color: #82e1b5 !important;
}
.remarks-container {
  display: flex; /* Use flexbox to align items horizontally */
}

.remarks {
  flex: 1; /* Each remarks div takes up equal space */
  margin-right: 20px; /* Optional: Adjust spacing between remarks */
}

@media print {
  .print-preview-button {
    display: none;
}

  .print-component {
    margin: 20px !important; 
    width: 95% !important;
  }
}
